import http from './http';


const base_path = '/sdata';


const HomeDate = () => {
    return http.get(`${base_path}/home_date?`);
}


export default {
    HomeDate,
};